import { Container, Image } from "@mantine/core";
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetCityQuery, useGetMatnasQuery } from "../../app/api/classes";
import { useGetSchoolsQuery } from "../../app/api/schools";
import { useGetStudentsQuery, useResetStudentsMutation } from "../../app/api/student";
import { City } from "../../app/types/classesTypes";
import plusIcon from "../../assets/icons/plus.svg";
import { AppButton } from "../../components/Button/Button";
import { AppInput } from "../../components/Input/Input";
import { AppSelect } from "../../components/Select/Select";
import { StudentItem } from "../../components/StudentItem/StudentItem";
import s from "./students.module.scss";
import { CreateStudentModal } from "../../components/CreateStudentModal/CreateStudentModal";
import { NotificationWithOptions } from "../../components/NotificationWithOptions/notificationWithOptions";
import { getLastEndDateOfStudyingYear } from "../../utils/date";

type Filters = { matnas: string | null; school: string | null; city: string | null };

export const Students = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState<Filters>({
    matnas: null,
    school: null,
    city: null,
  });
  const [debounced] = useDebouncedValue(query, 200);
  const [opened, { open, close }] = useDisclosure(false);
  const { data: students } = useGetStudentsQuery({
    fullName: debounced,
    cityId: filters.city || undefined,
    schoolId: filters.school || undefined,
    matnasId: filters.school || undefined,
  });
  const [resetStudents] = useResetStudentsMutation();
  const { data: cities } = useGetCityQuery();
  const { data: matnases } = useGetMatnasQuery({ cityId: filters.city || "" }, { skip: !filters.city });
  const { data: schools } = useGetSchoolsQuery({ cityId: filters.city || "" }, { skip: !filters.city });
  const [showNotification, setShowNotification] = useState(false);

  const date = new Date();
  const lastEndDateOfStudyingYear = getLastEndDateOfStudyingYear();

  return (
    <>
      {showNotification && (
        <NotificationWithOptions
          action={resetStudents}
          text={t("studentsPage.notification.resetStudents")}
          onClose={() => setShowNotification(false)}
        />
      )}
      <Container size={"xl"}>
        <div className={s.filtersWrapper}>
          <div>
            <div className={s.filtersItem}>
              <div>{t("studentsPage.filters.matnas")}</div>
              <AppSelect
                clearable
                data={matnases ? matnases.map((matnas) => ({ value: matnas.name, label: matnas.name })) : []}
                placeholder={t("studentsPage.filters.matnasPlaceholder")}
                value={filters.matnas}
                onChange={(value) => setFilters({ ...filters, matnas: value })}
              />
            </div>
          </div>
          <div>
            <div className={s.filtersItem}>
              <div>{t("studentsPage.filters.school")}</div>
              <AppSelect
                data={schools ? schools.map((schools) => ({ value: schools.name, label: schools.name })) : []}
                clearable
                placeholder={t("studentsPage.filters.schoolPlaceholder")}
                value={filters.school}
                onChange={(value) => setFilters({ ...filters, school: value })}
              />
            </div>
            <div className={s.filtersItem}>
              <div>{t("studentsPage.filters.city")}</div>
              {cities && (
                <AppSelect
                  clearable
                  data={cities.map((city: City) => ({ value: city._id, label: city.name }))}
                  placeholder={t("studentsPage.filters.cityPlaceholder")}
                  value={filters.city}
                  onChange={(value) => setFilters({ ...filters, city: value })}
                />
              )}
            </div>
          </div>
        </div>
        <div className={s.studentsSearch}>
          <AppInput placeholder={t("studentsPage.filters.searchPlaceholder")} onChange={(event) => setQuery(event.target.value)} />
        </div>
        <div className={s.studentsBtns}>
          <AppButton
            variant={"filled"}
            title={t("studentsPage.buttons.addStudent")}
            leftIcon={<Image src={plusIcon} />}
            style={{ background: "rgba(66, 72, 88, 0.5)", border: "none" }}
            onClick={() => open()}
          />
          {date > lastEndDateOfStudyingYear && (
            <AppButton variant={"filled"} title={t("studentsPage.buttons.resetStudents")} onClick={() => setShowNotification(true)} />
          )}
          <div className={s.studentsTitle}>{t("studentsPage.buttons.allStudents")}</div>
        </div>
        <div className={s.studentsList}>
          {students?.map((student) => (
            <StudentItem student={student} key={student._id} />
          ))}
        </div>
        <CreateStudentModal opened={opened} onClose={close} />
      </Container>
    </>
  );
};
