import { useDeleteCityMutation, useUpdateCityMutation } from "../../app/api/classes";
import React, { useState } from "react";
import { CreateUpdateCityModal } from "./CreateUpdateCityModal";
import s from "./cities.module.scss";
import { Button } from "@mantine/core";
import { City } from "../../app/types/classesTypes";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../app/contexts/NotificationContext";
import { isServerError } from "../../utils/isError";
import { NotificationWithOptions } from "../../components/NotificationWithOptions/notificationWithOptions";

type CitiesTableRowProps = {
  city: City;
};

export const CitiesTableRow = ({ city }: CitiesTableRowProps) => {
  const [deleteCity] = useDeleteCityMutation();
  const [updateCity] = useUpdateCityMutation();
  const [updateCityModalOpened, setUpdateCityModalOpened] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const [showDeleteNotification, setShowDeleteNotification] = useState(false);

  return (
    <>
      {showDeleteNotification && (
        <NotificationWithOptions
          action={() => {
            setShowDeleteNotification(false);
            deleteCity(city._id);
          }}
          text={`${t("citiesPage.deleteNotificationText")} ${city.name}`}
          onClose={() => setShowDeleteNotification(false)}
        />
      )}
      <CreateUpdateCityModal
        initFormValues={{ name: city.name }}
        title={t("citiesPage.updateCity")}
        opened={updateCityModalOpened}
        onClose={() => {
          setUpdateCityModalOpened(false);
        }}
        submitText={t("general.actions.update")}
        onSubmit={async ({ name }) => {
          setUpdateCityModalOpened(false);
          try {
            await updateCity({ _id: city._id, name }).unwrap();
          } catch (e) {
            if (isServerError(e)) {
              addNotification({ text: e.data.message, type: "error" });
            }
          }
        }}
      />
      <tr>
        <td className={s.nameTableCell}>{city.name}</td>
        <td>
          <div className={s.tableActions}>
            <Button
              onClick={() => {
                setUpdateCityModalOpened(true);
              }}
            >
              {t("general.actions.update")}
            </Button>
            <Button
              onClick={() => {
                setShowDeleteNotification(true);
              }}
            >
              {t("general.actions.delete")}
            </Button>
          </div>
        </td>
      </tr>
    </>
  );
};
