import "dayjs/locale/he";
import React, { FC, useState } from "react";
import arrowLeftIcon from "../../assets/icons/arrow-left.svg";
import { ActionIcon, Image } from "@mantine/core";
import { getStartOfWeek } from "../../utils/date";

type Props = {
  changeDate: (value: [Date | null, Date | null]) => void;
};

export const WeekRangePicker: FC<Props> = ({ changeDate }) => {
  const [value, setValue] = useState<[Date | null, Date | null]>([getStartOfWeek(new Date()), nextweek()]);

  function nextweek() {
    const today = getStartOfWeek(new Date());
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
  }
  function previousweek() {
    const today = getStartOfWeek(new Date());
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  }
  const getNextWeek = (currentDate: Date | null) => {
    if (currentDate) return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7);
    return nextweek();
  };

  const getPreviousWeek = (currentDate: Date | null) => {
    if (currentDate) return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
    return previousweek();
  };

  return (
    <>
      <ActionIcon
        variant={"transparent"}
        onClick={() => {
          setValue((prevDate) => [getPreviousWeek(prevDate[0]), getPreviousWeek(prevDate[1])]);
          if (value[0] !== null && value[1] !== null) {
            changeDate(value);
          }
        }}
      >
        <div style={{ background: "rgba(66, 72, 88, 0.5)", padding: "8px", borderRadius: "100%" }}>
          <Image src={arrowLeftIcon} style={{ minWidth: "24px" }} />
        </div>
      </ActionIcon>

      <ActionIcon
        variant={"transparent"}
        onClick={() => {
          setValue((prevDate) => [getNextWeek(prevDate[0]), getNextWeek(prevDate[1])]);
          if (value[0] !== null && value[1] !== null) {
            changeDate(value);
          }
        }}
      >
        <div style={{ background: "rgba(66, 72, 88, 0.5)", padding: "8px", borderRadius: "100%" }}>
          <Image src={arrowLeftIcon} style={{ minWidth: "24px", transform: "scaleX(-1)" }} />
        </div>
      </ActionIcon>
    </>
  );
};
