import { History } from "../../app/types/historyTypes";
import s from "./HistoryTable.module.scss";
import { HistoryFile } from "./HistoryFile";
import dayjs from "dayjs";
import { AppButton } from "../../components/Button/Button";
import { useDeleteHistoryMutation } from "../../app/api/history";
import { StudentsModal } from "./StudentModal";

type HistoryTableRowProps = {
  history: History;
};

export const HistoryTableRow = ({ history }: HistoryTableRowProps) => {
  const [deleteHistory] = useDeleteHistoryMutation();
  const handleRemoveHistory = async (historyId: string) => {
    try {
      await deleteHistory({ id: historyId });
    } catch (error) {}
  };
  return (
    <>
      <tr>
        <td className={s.nameTableCell}>{dayjs(history.createdAt).format("YYYY-MM-DD HH:mm")}</td>
        <td className={s.nameTableCell}> {history.file && <HistoryFile file={history.file} />}</td>
        <td className={s.nameTableCell}>{history.addedStudentsId.length === 0 ? "-" : history.city[0]}</td>
        <td className={s.nameTableCell}>{history.addedStudentsId.length === 0 ? "-" : history.matnas[0]}</td>
        <td className={s.nameTableCell}>
          <AppButton
            title={"general.actions.remove"}
            onClick={() => {
              handleRemoveHistory(history._id);
            }}
          />
          {history.addedStudentsId.length === 0 && (
           <StudentsModal history={history}/>
          )}
        </td>
      </tr>
    </>
  );
};
