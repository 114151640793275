import { Image } from "@mantine/core";
import { FC } from "react";
import styles from "./HistoryTable.module.scss";
import fileIcon from "../../assets/icons/file.svg";
import { HistoryFile as HistoryFileType } from "../../app/types/historyTypes";

interface Props {
  file: HistoryFileType;
  onDelete?: () => void;
}

export const HistoryFile: FC<Props> = ({ file }) => {
  return (
    <>
      {file.url && (
        <div className={styles.file}>
          <div className={styles.fileImage} onClick={() => window.open(file.url, "_blank")}>
            <Image src={fileIcon} style={{ maxWidth: "72px" }} />
          </div>
          <p>{file.name}</p>
        </div>
      )}
    </>
  );
};
