import React from "react";
import styles from "../classes.module.scss";
import { AppInput } from "../../../components/Input/Input";
import { AppSelect } from "../../../components/Select/Select";
import { City, Matnas } from "../../../app/types/classesTypes";
import { Teacher } from "../../../app/types/teacherTypes";
import { AppTimeInput } from "../../../components/TimeInput/TimeInput";
import { Checkbox } from "@mantine/core";
import { AppDatePicker } from "../../../components/Datepicker/Datepicker";
import { AppButton } from "../../../components/Button/Button";
import { AppModal } from "../../../components/Modal/Modal";
import { useForm } from "@mantine/form";
import dayjs from "dayjs";
import { useGetTeachersQuery } from "../../../app/api/teacher";
import { useAddClassMutation, useGetCityQuery, useGetMatnasQuery } from "../../../app/api/classes";
import { useGetSchoolsQuery } from "../../../app/api/schools";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";
import { getStartOfWeek } from "../../../utils/date";

type FormValues = {
  classname: string;
  location: string;
  groupLink: string;
  startTime?: string;
  endTime?: string;
  date?: string;
  startDate: string;
  endDate: string;
  teacherId: string;
  matnas: string;
  school: string;
  city: string;
  isRecuring?: boolean;
  recurringEndDate?: Date | string;
  institutionType?: InstitutionType | null;
};

const institutionTypes = ["matnas", "school"] as const;
type InstitutionType = (typeof institutionTypes)[number];

type AddClassModalProps = {
  opened: boolean;
  onClose: () => void;
};

const initialFormValues: FormValues = {
  classname: "",
  location: "",
  groupLink: "",
  startTime: "",
  endTime: "",
  date: "",
  startDate: "",
  endDate: "",
  teacherId: "",
  matnas: "",
  school: "",
  city: "",
  isRecuring: false,
  recurringEndDate: getStartOfWeek(new Date()),
  institutionType: null,
};

export const AddClassModal = ({ opened, onClose }: AddClassModalProps) => {
  const { values, setValues, reset, getInputProps, onSubmit } = useForm<FormValues>({
    initialValues: initialFormValues,
    validate: {
      classname: (value) => {
        if (value.trim().length < 5) {
          return t("classesPage.validations.classLength");
        }
      },
      location(value) {
        if (value.trim().length < 5) {
          return t("classesPage.validations.locationLength");
        }
      },
      groupLink(value) {
        if (!value) {
          return t("classesPage.validations.groupLinkRequired");
        }
      },
      date(value) {
        if (!value) {
          return t("classesPage.validations.dateRequired");
        }
      },
      teacherId(value) {
        if (!value) {
          return t("classesPage.validations.teacherRequired");
        }
      },
      institutionType(value) {
        if (!value) {
          return t("classesPage.validations.institutionTypeRequired");
        }
      },
      matnas(value, values) {
        if (!value && values.institutionType === "matnas") {
          return t("classesPage.validations.matnasRequired");
        }
      },
      school(value, values) {
        if (!value && values.institutionType === "school") {
          return t("classesPage.validations.schoolRequired");
        }
      },
      city(value) {
        if (!value) {
          return t("classesPage.validations.cityRequired");
        }
      },
      startTime(value) {
        if (!value) {
          return t("classesPage.validations.startTimeRequired");
        }
      },
      endTime(value) {
        if (!value) {
          return t("classesPage.validations.endTimeRequired");
        }
      },
    },
    transformValues: (values: FormValues) => ({
      startDate: dayjs(`${values.date}T${values.startTime}`).toISOString(),
      endDate: dayjs(`${values.date}T${values.endTime}`).toISOString(),
      classname: values.classname,
      location: values.location,
      groupLink: values.groupLink,
      teacherId: values.teacherId,
      matnas: values.matnas,
      school: values.school,
      city: values.city,
      isRecuring: values.isRecuring,
      recurringEndDate: values.isRecuring ? dayjs(values.recurringEndDate).toISOString() : undefined,
    }),
  });
  const { data: teachersList } = useGetTeachersQuery({});
  const { data: cities } = useGetCityQuery();
  const { data: matnases } = useGetMatnasQuery(values.city ? { cityId: values.city } : {});
  const { data: schools } = useGetSchoolsQuery(values.city ? { cityId: values.city } : {});
  const { t } = useTranslation();
  const desktop = useMediaQuery("(min-width: 992px)");
  const [addClass] = useAddClassMutation();

  const addClassHandler = async (values: FormValues) => {
    try {
      await addClass({
        name: values.classname,
        location: values.location,
        groupLink: values.groupLink,
        teacherId: values.teacherId,
        startDate: values.startDate,
        endDate: values.endDate,
        matnasId: values.matnas ? values.matnas : undefined,
        schoolId: values.school ? values.school : undefined,
        cityId: values.city,
        isRecurring: values.isRecuring,
        recurringEndDate: values.isRecuring ? dayjs(values.recurringEndDate).toISOString() : undefined,
      }).unwrap();
      reset();
      onClose();
    } catch (error) {}
  };

  const getWeekdays = () => {
    const currentDate = dayjs();

    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const date = currentDate.add(i, "day");
      const object = {
        value: date.format("YYYY-MM-DD"),
        label: date.format("dddd"),
      };
      weekDates.push(object);
    }

    return weekDates;
  };

  return (
    <AppModal status={opened} onClose={onClose} size={desktop ? "md" : "xs"}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>{t("classesPage.text.addClass")}</div>
        <form onSubmit={onSubmit((values) => addClassHandler(values))} className={styles.form}>
          <AppInput placeholder={t("classesPage.form.classNamePlaceholder")} {...getInputProps("classname")} />
          <AppInput placeholder={t("classesPage.form.locationPlaceholder")} {...getInputProps("location")} />
          {cities && (
            <AppSelect
              data={cities.map((city) => ({ value: city._id, label: city.name }))}
              placeholder={t("classesPage.form.cityPlaceholder")}
              {...getInputProps("city")}
            />
          )}
          <AppSelect
            data={institutionTypes.map((type) => ({ value: type, label: type }))}
            placeholder={t("classesPage.form.institutionTypePlaceholder")}
            {...getInputProps("institutionType")}
            onChange={(value) => {
              setValues({ institutionType: value as InstitutionType, matnas: "", school: "" });
            }}
          />
          {values.institutionType === "matnas" && (
            <AppSelect
              data={matnases ? matnases.map((matnas) => ({ value: matnas._id, label: matnas.name })) : []}
              placeholder={t("classesPage.form.matnasPlaceholder")}
              {...getInputProps("matnas")}
            />
          )}
          {values.institutionType === "school" && (
            <AppSelect
              data={schools ? schools.map((school) => ({ value: school._id, label: school.name })) : []}
              placeholder={t("classesPage.form.schoolPlaceholder")}
              {...getInputProps("school")}
            />
          )}
          <AppInput placeholder={t("classesPage.form.groupLinkPlaceholder")} {...getInputProps("groupLink")} />
          {teachersList && (
            <AppSelect
              data={teachersList.map((teacher: Teacher) => ({ value: teacher._id, label: teacher.fullName }))}
              placeholder={t("classesPage.form.teacherIdPlaceholder")}
              {...getInputProps("teacherId")}
            />
          )}
          <AppSelect
            {...getInputProps("date")}
            data={getWeekdays()}
            placeholder={t("classesPage.form.datePlaceholder")}
            onChange={(value) => setValues({ date: value || undefined })}
          />
          <div className={styles.dates}>
            <div className={styles.datesTime}>
              <div className={styles.datesTimePart}>
                <AppTimeInput {...getInputProps("startTime")} />
                <span>{t("general.from")}</span>
              </div>
              <div className={styles.datesTimePart}>
                <AppTimeInput {...getInputProps("endTime")} />
                <span>{t("general.to")}</span>
              </div>
            </div>
          </div>
          <Checkbox
            checked={values.isRecuring}
            labelPosition="right"
            label={"Is reccuring?"}
            color="dark"
            radius="xs"
            onChange={(event) => {
              setValues({ isRecuring: event.currentTarget.checked });
            }}
            style={{ marginLeft: "auto" }}
          />
          {values.isRecuring && (
            <AppDatePicker
              style={{ textAlign: "right" }}
              changeDate={(value) => setValues({ recurringEndDate: value ? value : getStartOfWeek(new Date()) })}
            />
          )}

          <div className={styles.formBtns}>
            <AppButton title={t("classesPage.form.cancel")} variant={"outline"} onClick={onClose} />
            <AppButton title={t("classesPage.form.submit")} variant={"filled"} type={"submit"} />
          </div>
        </form>
      </div>
    </AppModal>
  );
};
