import { Flex, Image } from "@mantine/core";
import React, { FC, useState } from "react";
import { Student } from "../../app/types/studentTypes";
import { AppButton } from "../Button/Button";
import s from "./StudentItem.module.scss";

import { useDeleteStudentMutation, useUpdateStudentMutation } from "../../app/api/student";
import listIcon from "../../assets/icons/list.svg";
import cityIcon from "../../assets/icons/city.svg";
import schoolIcon from "../../assets/icons/school.svg";
import minusIcon from "../../assets/icons/minus.svg";
import { Notification } from "../Notification/notification";
import { NotificationWithOptions } from "../NotificationWithOptions/notificationWithOptions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

type StudentItemProps = {
  student: Student;
};

export const StudentItem: FC<StudentItemProps> = ({ student }: StudentItemProps) => {
  const { t } = useTranslation();
  const [deleteStudent] = useDeleteStudentMutation();
  const [updateStudent, { isLoading }] = useUpdateStudentMutation();
  const [isStudentDeleted, setIsStudentDeleted] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);

  const deleteStudentHandler = async () => {
    try {
      await deleteStudent({
        id: student._id,
      }).unwrap();

      setShowNotification(false);
      setIsStudentDeleted(true);
    } catch (error) {
    } finally {
      setTimeout(() => {
        setIsStudentDeleted(false);
      }, 3000);
    }
  };

  return (
    <>
      {showNotification && (
        <NotificationWithOptions
          action={deleteStudentHandler}
          text={`${t("studentsItem.notification.goingDeleteStudentNotification")} ${student.fullName}`}
          onClose={() => setShowNotification(false)}
        />
      )}

      {isStudentDeleted && <Notification text={t("studentsItem.notification.studentRemoved")} />}
      <Link key={student._id} to={`/students/${student._id}`} className={clsx(s.student, !student.isApproved && s.unapprovedStudent)}>
        <Flex align={"center"} gap={20}>
          <AppButton
            variant={"outline"}
            title={t("general.actions.remove")}
            leftIcon={<Image src={minusIcon} />}
            onClick={(e: any) => {
              e.preventDefault();
              setShowNotification(true);
            }}
          />
          {!student.isApproved && (
            <AppButton
              type={"button"}
              variant={"filled"}
              title={"Approve"}
              loading={isLoading}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                updateStudent({ id: student._id, body: { isApproved: true } });
              }}
            />
          )}
        </Flex>
        <div className={s.infoBlock}>
          <div className={s.studentName}>{student.fullName}</div>
          <div className={s.studentClassesWrap}>
            <span className={s.studentClasses}>{student.matnas?.name}</span>
            <div className={s.iconBlockTop}>
              <div className={s.iconBlock}>
                <Image src={listIcon} className={s.icon} />
                <span className={s.studentClasses}>
                  {student.classesCount} {student.classesCount === 1 ? "class" : "classes"}
                </span>
              </div>
              <div className={s.iconBlock}>
                <Image src={schoolIcon} className={s.icon} />
                <span className={s.studentClasses}>{student.school?.name}</span>
              </div>
              <div className={s.iconBlock}>
                <Image src={cityIcon} className={s.icon} />
                <span className={s.studentClasses}>{student.city.name}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};
