export const getStartOfWeek = (currentDate: Date) => {
  const date = new Date(currentDate);
  const day = date.getDay();
  const diff = (day === 0 ? -6 : 1) - day;
  date.setDate(currentDate.getDate() + diff);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getLastStartDateOfStudyingYear = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const firstSeptemberThisYear = new Date(currentYear, 8, 1);
  if (today >= firstSeptemberThisYear) {
    return firstSeptemberThisYear;
  } else {
    return new Date(currentYear - 1, 8, 1);
  }
};

export const getLastEndDateOfStudyingYear = () => {
  const lastStartDateOfStudyingYear = getLastStartDateOfStudyingYear();
  return new Date(lastStartDateOfStudyingYear.getFullYear() + 1, 5, 30);
};
