import React from "react";
import { useGetStudentsAttendanceQuery } from "../../app/api/student";
import { Container, Table } from "@mantine/core";
import s from "./students-attendance.module.scss";
import { StudentAttendanceTableRow } from "./StudentAttendanceTableRow";
import { useTranslation } from "react-i18next";

export const StudentsAttendancePage = () => {
  const { data: studentsAttendance } = useGetStudentsAttendanceQuery();
  const { t } = useTranslation();

  return (
    <Container size={"xl"}>
      <Table className={s.table}>
        <thead>
          <tr>
            <th>{t("studentsAttendancePage.studentFullNameColumnTitle")}</th>
            <th>{t("studentsAttendancePage.presentColumnTitle")}</th>
            <th>{t("studentsAttendancePage.absentColumnTitle")}</th>
            <th>{t("studentsAttendancePage.actionsColumnTitle")}</th>
          </tr>
        </thead>
        <tbody>
          {studentsAttendance?.map((sa) => (
            <StudentAttendanceTableRow studentAttendance={sa} key={sa._id} />
          ))}
        </tbody>
      </Table>
    </Container>
  );
};
