import React, { useState } from "react";
import { AppButton } from "../../../components/Button/Button";
import s from "../classes.module.scss";
import { Flex, Modal } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ExportClassesToCSVFileButton } from "./ExportClassesToCSVFileButton";
import { AppDateRangePicker } from "../../../components/DateRangePicker/DateRangePicker";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

type FormValues = {
  dates: [Date | null, Date | null];
};

export const ExportClassesButton = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const { reset, values, setFieldValue } = useForm<FormValues>({
    initialValues: { dates: getInitialDates() },
  });
  const [startDate, endDate] = values.dates;
  const { t } = useTranslation();

  function getInitialDates() {
    const today = dayjs();
    return [today.toDate(), today.add(1, "week").toDate()] as [Date, Date];
  }
  return (
    <>
      <Modal
        opened={modalOpened}
        onClose={() => {
          reset();
          setModalOpened(false);
        }}
        withCloseButton={false}
        centered
      >
        <form className={s.modal}>
          <div className={s.modalHeader}>{t("classesPage.actions.exportClasses")}</div>
          <AppDateRangePicker changeDate={(value) => setFieldValue("dates", value)} />
          <Flex justify={"flex-end"} pt={20}>
            <ExportClassesToCSVFileButton startDate={startDate || undefined} endDate={endDate || undefined} />
          </Flex>
        </form>
      </Modal>
      <AppButton
        type={"button"}
        variant={"filled"}
        title={t("classesPage.actions.exportClasses")}
        onClick={() => {
          setModalOpened(true);
        }}
      />
    </>
  );
};
