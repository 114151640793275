import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";
import { History, HistoryStudent, ResponceGPTHistory, UpdateHistory, UpdateHistoryStudents } from "../types/historyTypes";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,

  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token || localStorage.getItem("auth_token");

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const historyApi = createApi({
  reducerPath: "historyApi",
  baseQuery: baseQuery,
  tagTypes: ["History"],
  endpoints: (builder) => ({
    getHistory: builder.query<History[], void>({
      query: () => ({
        url: `/history`,
        method: "GET",
      }),
      providesTags: ["History"],
    }),
    getPdfFromEmail: builder.mutation<void, void>({
      query: () => ({
        url: `/history/email`,
        method: "GET",
      }),
      invalidatesTags: ["History"],
    }),
    uploadPdfGptFile: builder.mutation<ResponceGPTHistory, { body: FormData }>({
      query: ({ body }) => ({
        url: `/history/file`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["History"],
    }),
    uploadHistory: builder.mutation<void, { body: UpdateHistory }>({
      query: ({ body }) => ({
        url: `/history/file/students`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["History"],
    }),
    updateHistoryStudents: builder.mutation<void, { body: UpdateHistoryStudents }>({
      query: ({ body }) => ({
        url: `/history/students/${body.historyId}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["History"],
    }),
    deleteHistory: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/history/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["History"],
    }),
  }),
});

export const {
  useGetHistoryQuery,
  useLazyGetHistoryQuery,
  useUploadPdfGptFileMutation,
  useDeleteHistoryMutation,
  useUploadHistoryMutation,
  useGetPdfFromEmailMutation,
  useUpdateHistoryStudentsMutation
} = historyApi;
