import React, { useState } from "react";
import { useCreateMatnasMutation, useGetMatnasQuery } from "../../app/api/classes";
import { CreateUpdateMatnasModal } from "./CreateUpdateMatnasModal";
import { Button, Container, Table } from "@mantine/core";
import { MatnasTableRow } from "./MatnasTableRow";
import s from "./matnases.module.scss";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../app/contexts/NotificationContext";
import { isServerError } from "../../utils/isError";

export const MatnasesPage = () => {
  const { data: matnases } = useGetMatnasQuery({});
  const [createMatnas] = useCreateMatnasMutation();
  const [createMatnasModalOpened, setCreateMatnasModalOpened] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();

  return (
    <>
      <CreateUpdateMatnasModal
        title={t(`matnasesPage.addMatnas`)}
        opened={createMatnasModalOpened}
        onClose={() => {
          setCreateMatnasModalOpened(false);
        }}
        submitText={t(`general.actions.add`)}
        onSubmit={async ({ name, cityId }) => {
          setCreateMatnasModalOpened(false);
          try {
            await createMatnas({ name, cityId }).unwrap();
          } catch (e) {
            if (isServerError(e)) {
              addNotification({ text: e.data.message, type: "error" });
            }
          }
        }}
      />
      <Container size={"xl"}>
        <Button
          onClick={() => {
            setCreateMatnasModalOpened(true);
          }}
        >
          {t("matnasesPage.addMatnas")}
        </Button>
        <Table className={s.table}>
          <thead>
            <tr>
              <th>{t("matnasesPage.matnasName")}</th>
              <th>{t("matnasesPage.city")}</th>
              <th>{t("matnasesPage.actionsColumnTitle")}</th>
            </tr>
          </thead>
          <tbody>
            {matnases?.map((matnas) => (
              <MatnasTableRow matnas={matnas} key={matnas._id} />
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};
