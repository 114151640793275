import React, { useState } from "react";
import { CreateUpdateMatnasModal } from "./CreateUpdateMatnasModal";
import s from "./matnases.module.scss";
import { Button } from "@mantine/core";
import { Matnas } from "../../app/types/classesTypes";
import { useDeleteMatnasMutation, useUpdateMatnasMutation } from "../../app/api/classes";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../app/contexts/NotificationContext";
import { isServerError } from "../../utils/isError";
import { NotificationWithOptions } from "../../components/NotificationWithOptions/notificationWithOptions";

type MatnasTableRowProps = {
  matnas: Matnas;
};

export const MatnasTableRow = ({ matnas }: MatnasTableRowProps) => {
  const [deleteMatnas] = useDeleteMatnasMutation();
  const [updateMatnas] = useUpdateMatnasMutation();
  const [updateMatnasModalOpened, setUpdateMatnasModalOpened] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const [showDeleteNotification, setShowDeleteNotification] = useState(false);

  return (
    <>
      {showDeleteNotification && (
        <NotificationWithOptions
          action={() => {
            setShowDeleteNotification(false);
            deleteMatnas(matnas._id);
          }}
          text={`${t("matnasesPage.deleteNotificationText")} ${matnas.name}`}
          onClose={() => setShowDeleteNotification(false)}
        />
      )}
      <CreateUpdateMatnasModal
        initFormValues={{ name: matnas.name, cityId: matnas.city._id }}
        title={t(`matnasesPage.updateMatnas`)}
        opened={updateMatnasModalOpened}
        onClose={() => {
          setUpdateMatnasModalOpened(false);
        }}
        submitText={t(`general.actions.update`)}
        onSubmit={async ({ name, cityId }) => {
          setUpdateMatnasModalOpened(false);
          try {
            await updateMatnas({ _id: matnas._id, name, cityId }).unwrap();
          } catch (e) {
            if (isServerError(e)) {
              addNotification({ text: e.data.message, type: "error" });
            }
          }
        }}
      />
      <tr>
        <td className={s.nameTableCell}>{matnas.name}</td>
        <td className={s.nameTableCell}>{matnas.city.name}</td>
        <td>
          <div className={s.tableActions}>
            <Button
              onClick={() => {
                setUpdateMatnasModalOpened(true);
              }}
            >
              {t("general.actions.update")}
            </Button>
            <Button
              onClick={() => {
                setShowDeleteNotification(true);
              }}
            >
              {t("general.actions.delete")}
            </Button>
          </div>
        </td>
      </tr>
    </>
  );
};
