import { Image, Popover, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import locationIcon from "../../../assets/icons/location.svg";
import studentsIcon from "../../../assets/icons/students.svg";
import { ClassType } from "../ClassesTable";
import styles from "../ClassesTable.module.scss";
import DeleteClassModal from "./DeleteClassModal";

interface Props {
  cls: ClassType;
  isChangable: boolean;
}

export const TableItem: FC<Props> = ({ cls, isChangable }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [opened, { toggle, close }] = useDisclosure(false);
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);

  useEffect(() => {
    close();
  }, [isChangable]);

  return (
    <>
      <DeleteClassModal opened={deleteModalOpened} onClose={closeDeleteModal} cls={cls} />
      <Popover opened={opened}>
        <Popover.Target>
          <div
            className={styles.bodyItemSection}
            key={cls.name}
            onClick={() => (isChangable ? toggle() : navigate({ pathname: `/classes/${cls._id}` }))}
          >
            <div className={styles.bodyItemSectionInner}>
              <div className={styles.bodyItemSectionName}>{cls.name}</div>
              <div className={styles.bodyItemSectionInfo}>
                <div className={styles.bodyItemSectionClsCount}>
                  <Image src={studentsIcon} style={{ maxWidth: "10px" }} />
                  <span>{cls.studentsCount}</span>
                </div>
                <div className={styles.bodyItemSectionLocation}>
                  <Image src={locationIcon} style={{ maxWidth: "10px" }} />
                  <span>{cls.location}</span>
                </div>
              </div>
            </div>
            <div className={styles.bodyItemSectionTime}>
              <div className={styles.bodyItemSectionTimeLabel}>{cls.start}</div>
              <div className={styles.bodyItemSectionTimeLabel}>{cls.end}</div>
            </div>
          </div>
        </Popover.Target>
        <Popover.Dropdown
          onClick={() => {
            openDeleteModal();
            close();
          }}
        >
          <div className={styles.popover}>
            <UnstyledButton className={styles.popoverBtn}>{t("general.actions.delete")}</UnstyledButton>
          </div>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
