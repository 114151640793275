import React, { useEffect } from "react";
import { useLazyGetStudentFullAttendanceQuery } from "../../app/api/teacher";
import dayjs from "dayjs";
import { AppButton } from "../Button/Button";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

type ExportStudentAttendanceButtonProps = {
  studentId: string;
  startDate?: Date;
  endDate?: Date;
  fileName?: string;
};

export const ExportStudentAttendanceButton = ({ studentId, startDate, endDate, fileName }: ExportStudentAttendanceButtonProps) => {
  const [trigger, { data: fullAttendance, isLoading, status }] = useLazyGetStudentFullAttendanceQuery({
    selectFromResult: (state) => ({
      ...state,
      data: state.data?.map((el) => ({
        ...el,
        startDate: dayjs(el.startDate).format("YYYY-MM-DD HH:mm"),
        endDate: dayjs(el.endDate).format("YYYY-MM-DD HH:mm"),
        present: el.present ? "Yes" : "No",
      })),
    }),
  });
  const csvLinkId = "export-student-attendance-csv-link";
  const { t } = useTranslation();

  useEffect(() => {
    if (status === "fulfilled") {
      document.getElementById(csvLinkId)?.click();
    }
  }, [status]);

  return (
    <>
      <AppButton
        variant={"filled"}
        title={t("studentsAttendancePage.exportAttendanceButton")}
        onClick={() =>
          trigger({
            studentId,
            ...(startDate && { startDate: dayjs(startDate).toISOString() }),
            ...(endDate && { endDate: dayjs(endDate).toISOString() }),
          })
        }
        loading={isLoading}
      />
      {fullAttendance && <CSVLink id={csvLinkId} data={fullAttendance} filename={fileName || "student-attendance.csv"} />}
    </>
  );
};
