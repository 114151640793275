import React from "react";
import s from "../../Cities/cities.module.scss";
import { Box, Table, Title } from "@mantine/core";
import { DeletedStudentCountPerCityElement } from "../../../app/types/studentTypes";
import { useTranslation } from "react-i18next";

type DeletedStudentsCountTableProps = { data: DeletedStudentCountPerCityElement[] };

export const DeletedStudentsCountTable = ({ data }: DeletedStudentsCountTableProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Title order={3}>{t("historyPage.deletedStudentsCountTitle")}</Title>
      <div style={{ maxHeight: 400, overflowY: "auto" }}>
        <Table className={s.table} w={400}>
          <thead>
            <tr>
              <Box w={300} component={"th"}>
                {t("historyPage.cityColumnTitle")}
              </Box>
              <th>{t("historyPage.countColumnTitle")}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((el) => (
              <tr key={el.city}>
                <td>{el.city}</td>
                <td>{el.count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
