import React, { useMemo, useState } from "react";
import { Center, Container, Loader, Stack } from "@mantine/core";
import { AppSelect } from "../../components/Select/Select";
import dayjs from "dayjs";
import { useGetDeletedStudentsCountPerCityQuery, useGetStudentsCountPerCityAndMatnasQuery } from "../../app/api/student";
import { DeletedStudentsCountTable } from "./DeletedStudentsCountTable/DeletedStudentsCountTable";
import { StudentsCountTable } from "./StudentsCountTable/StudentsCountTable";
import { useGetClassesPerMatnasQuery } from "../../app/api/classes";
import { ClassesPerMatnasTable } from "./ClassesPerMatnasTable/ClassesPerMatnasTable";
import { useTranslation } from "react-i18next";
import { HistoryTable } from "../../components/History/HistoryTable";

export const HistoryPage = () => {
  const yearsOptions = useMemo(() => {
    const startYear = 2023;
    const currentDate = new Date();
    let firstSeptember = new Date(startYear, 8, 1);
    const years = [];
    while (firstSeptember < currentDate) {
      years.push(firstSeptember.getFullYear());
      firstSeptember = dayjs(firstSeptember).add(1, "year").toDate();
    }
    return years.map((year) => {
      const stringYear = year.toString();
      return { value: stringYear, label: stringYear };
    });
  }, []);
  const [year, setYear] = useState<string | null>(yearsOptions.at(-1)!.value);
  const { data: deletedStudentsCountPerCity, isLoading: isDeletedStudentsCountPerCityLoading } = useGetDeletedStudentsCountPerCityQuery({
    year: +(year as string),
  });
  const { data: studentsCountPerCityAndMatnas, isLoading: isStudentsCountPerCityAndMatnasLoading } = useGetStudentsCountPerCityAndMatnasQuery({
    year: +(year as string),
  });
  const { data: classesPerMatnas, isLoading: isClassesPerMatnasLoading } = useGetClassesPerMatnasQuery({
    year: +(year as string),
  });
  const { t } = useTranslation();

  const isLoading = isDeletedStudentsCountPerCityLoading || isStudentsCountPerCityAndMatnasLoading || isClassesPerMatnasLoading;

  return (
    <Container size={"xl"}>
      <HistoryTable />
      <AppSelect
        data={yearsOptions}
        label={t("historyPage.yearSelectLabel")}
        value={year}
        onChange={(value) => setYear(value)}
        w={100}
        mb={20}
        styles={{ label: { color: "white", paddingBottom: 10, paddingTop: 20 } }}
      />
      {isLoading ? (
        <Center h={400}>
          <Loader />
        </Center>
      ) : (
        <Stack spacing={"xl"}>
          {deletedStudentsCountPerCity && <DeletedStudentsCountTable data={deletedStudentsCountPerCity} />}
          {studentsCountPerCityAndMatnas && <StudentsCountTable data={studentsCountPerCityAndMatnas} />}
          {classesPerMatnas && <ClassesPerMatnasTable data={classesPerMatnas} />}
        </Stack>
      )}
    </Container>
  );
};
