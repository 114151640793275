import { Flex, Modal, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect } from "react";
import s from "./cities.module.scss";
import { AppInput } from "../../components/Input/Input";
import { AppButton } from "../../components/Button/Button";
import { useTranslation } from "react-i18next";

type FormValues = {
  name: string;
};

type CreateCityModalProps = {
  opened: boolean;
  onSubmit: (values: FormValues) => void;
  onClose: () => void;
  submitText: string;
  title: string;
  initFormValues?: { name: string };
};

export const CreateUpdateCityModal = ({ opened, onSubmit: onSubmitProp, onClose, submitText, title, initFormValues }: CreateCityModalProps) => {
  const { t } = useTranslation();
  const { setFieldValue, reset, getInputProps, onSubmit } = useForm<FormValues>({
    initialValues: {
      name: "",
    },
    validate: {
      name: (value) => {
        if (value.trim().length === 0) {
          return "Name is required";
        }
      },
    },
  });

  useEffect(() => {
    if (!initFormValues) return;
    setFieldValue("name", initFormValues.name);
  }, [initFormValues?.name]);

  return (
    <Modal opened={opened} onClose={onClose} withCloseButton={false} centered>
      <form
        className={s.modal}
        onSubmit={onSubmit((values) => {
          reset();
          onSubmitProp(values);
        })}
      >
        <div className={s.modalHeader}>{title}</div>
        <Stack>
          <AppInput placeholder={t("citiesPage.cityName")} {...getInputProps("name")} withAsterisk />
          <Flex justify={"flex-end"}>
            <AppButton title={submitText} variant={"filled"} type={"submit"} />
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
};
