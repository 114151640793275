import { Container, Image } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import pencilIcon from "../../assets/icons/pencil.svg";
import plusIcon from "../../assets/icons/plus.svg";
import { AppButton } from "../../components/Button/Button";
import { ClassesTable } from "../../components/ClassesTable/ClassesTable";
import { AppDateRangePicker } from "../../components/DateRangePicker/DateRangePicker";
import styles from "./classes.module.scss";
import { ExportClassesButton } from "./components/ExportClassesButton";
import useAuth from "../../app/hooks/useAuth";
import { WeekRangePicker } from "../../components/WeekRangePicker/WeekRangePicker";
import { AddClassModal } from "./components/AddClassModal";
import { getStartOfWeek } from "../../utils/date";

export const AppClasses = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const teacherId = user?.teacherId;
  const [isChangeable, setIsChangeable] = useState<boolean>(false);
  const [date, setDate] = useState<[Date | null, Date | null]>(() => {
    const monday = getStartOfWeek(new Date());
    const sunday = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 6);
    return [monday, sunday];
  });
  const [addModalOpened, { open: openAddModal, close: closeAddModal }] = useDisclosure(false);

  return (
    <Container size={"xl"}>
      <div className={styles.navigation}>
        {teacherId ? (
          <WeekRangePicker changeDate={(value) => setDate(value)} />
        ) : (
          <>
            <ExportClassesButton />
            <AppDateRangePicker changeDate={(value) => setDate(value)} />
          </>
        )}
      </div>
      <div className={teacherId ? styles.classesManage : styles.managing}>
        {teacherId ? (
          <div className={styles.classesTitle}>{t("classesPage.text.thisWeekClasses")}</div>
        ) : (
          <>
            <div className={styles.classesManage}>
              <AppButton
                variant={"subtle"}
                title={isChangeable ? "classesPage.actions.finishManage" : "classesPage.actions.manageClasses"}
                leftIcon={
                  <Image
                    src={pencilIcon}
                    style={{
                      background: isChangeable ? "#2064CB" : "rgba(66, 72, 88, 0.5)",
                      padding: "8px",
                      borderRadius: "100%",
                    }}
                  />
                }
                className={styles.classesManageBtn}
                onClick={() => setIsChangeable((prev) => !prev)}
              />
            </div>
            <div className={styles.classesBtns}>
              <AppButton
                variant={"filled"}
                title="classesPage.actions.addClass"
                leftIcon={<Image src={plusIcon} />}
                style={{ background: "rgba(66, 72, 88, 0.5)", border: "none" }}
                onClick={() => openAddModal()}
              />
              <div className={styles.classesTitle}>{t("classesPage.text.thisWeekClasses")}</div>
            </div>
          </>
        )}
      </div>

      {date && <ClassesTable startDate={date ? date[0] : null} endDate={date ? date[1] : null} teacherId={teacherId} isChangable={isChangeable} />}
      <AddClassModal opened={addModalOpened} onClose={closeAddModal} />
    </Container>
  );
};
