import { useTranslation } from "react-i18next";
import { useGetCityQuery, useGetMatnasQuery } from "../../app/api/classes";
import { useDisclosure } from "@mantine/hooks";
import { useRef, useState } from "react";
import { FormErrors, useForm } from "@mantine/form";
import { AppButton } from "../../components/Button/Button";
import styles from "./HistoryTable.module.scss";
import { AppModal } from "../../components/Modal/Modal";
import { ActionIcon, Image, Text } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import closeIcon from "../../assets/icons/close-menu.svg";
import { AppInput } from "../../components/Input/Input";
import { AppSelect } from "../../components/Select/Select";
import { City, Matnas } from "../../app/types/classesTypes";
import { useGetPdfFromEmailMutation, useUploadHistoryMutation, useUploadPdfGptFileMutation } from "../../app/api/history";
import { HistoryStudent } from "../../app/types/historyTypes";
import { StudentEditor } from "./StudentEditor";

interface FormValues {
  file: File | null;
  classname: string;
  matnas: string;
  city: string;
}

const removeDuplicates = (arr: string[]): string[] => arr.filter((item, index) => arr.indexOf(item) === index);

const groupStudentErrors = (errors: FormErrors) => {
  const groupedErrors: Record<number, Record<string, string>> = {};

  Object.keys(errors).forEach((key) => {
    const match = key.match(/students\.(\d+)\.(\w+)/);
    if (match) {
      const [, index, field] = match;
      const studentIndex = parseInt(index, 10);

      if (!groupedErrors[studentIndex]) {
        groupedErrors[studentIndex] = {};
      }

      groupedErrors[studentIndex][field] = errors[key] as string;
    }
  });

  return groupedErrors;
};

export const PdfUpload = () => {
  const { t } = useTranslation();
  const [uploadPdfGptFile, { isLoading }] = useUploadPdfGptFileMutation();
  const [uploadHistory] = useUploadHistoryMutation();
  const { data: cities } = useGetCityQuery();
  const { data: matnases } = useGetMatnasQuery({});
  const [opened, { open, close }] = useDisclosure();
  const [openedStudents, { open: openStudents, close: closeStudents }] = useDisclosure();
  const [updatedStudents, setUpdatedStudents] = useState<HistoryStudent[]>([]);
  const [fileId, setFileId] = useState<string>("");
  const [matnasState, setMatnasState] = useState<string[]>([]);
  const [classState, setClassState] = useState<string[]>([]);
  const [cityState, setCityState] = useState<string[]>([]);

  const [getPdfFromEmail, { isLoading: isPdfLoading }] = useGetPdfFromEmailMutation();
  const openRef = useRef<any>();

  const form = useForm({
    initialValues: {
      file: null,
      classname: "",
    } as FormValues,
    validate: {
      file: (value) => {
        if (!value) {
          return t("components.files.validation.file");
        }
      },
      matnas: (value) => (value ? null : t("general.required")),
      city: (value) => (value ? null : t("general.required")),
    },
  });

  const form_modal = useForm({
    initialValues: {
      city: '',
      matnas: '',
      classname: '',
      students: updatedStudents ?? []
    },
    validate: {
      matnas: (value) => (value ? null : t("general.required")),
      city: (value) => (value ? null : t("general.required")),
      students: {
        name: (value) => (value ? null : t("general.required")),
        phone: (value) => (value ? null : t("general.required")),
      },
    }
  });

  const uploadFilesHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("file", form.values.file as File);

      const res = await uploadPdfGptFile({ body: formData }).unwrap();
      const res_matnas = removeDuplicates([...res.matnas, form.values.matnas]);
      const res_city = removeDuplicates([...res.city, form.values.city]);
      const res_classname = removeDuplicates([...res.classname, form.values.classname]);
      setFileId(res.fileId);
      setUpdatedStudents(res.students);
      form_modal.setFieldValue('students', res.students);
      setMatnasState(res_matnas);
      setClassState(res_classname);
      setCityState(res_city);
      close();
      openStudents();
    } catch (error) {}
  };

  const handleStudentChange = (index: number, updatedStudent: HistoryStudent) => {
    const newStudents = [...updatedStudents];
    newStudents[index] = updatedStudent;
    setUpdatedStudents(newStudents);
    form_modal.setFieldValue('students', newStudents);
  };

  const handleStudentDelete = (index: number) => {
    const newStudents = updatedStudents.filter((_, i) => i !== index);
    setUpdatedStudents(newStudents);
    form_modal.setFieldValue('students', newStudents);
  };

  const handleSave = async () => {
    if (!form_modal.validate().hasErrors) {
      const body = {
        fileId: fileId,
        city: form_modal.values.city,
        matnas: form_modal.values.matnas,
        classname: form_modal.values.classname,
        students: updatedStudents,
      };
  
      try {
        await uploadHistory({ body });
        closeStudents();
        form.reset();
      } catch (error) {}
    }
  };

  const studentErrors = groupStudentErrors(form_modal.errors);

  return (
    <>
      <AppButton
        title={"historyPage.getPdf"}
        onClick={async () => {
          await getPdfFromEmail();
        }}
        isLoading={isPdfLoading}
      />
      <AppModal
        status={openedStudents}
        onClose={() => {
          form.reset();
          closeStudents();
        }}
        size={"xl"}
      >
        <div className={styles.modal}>
          <div className={styles.modalHead}>
            {cityState.length > 0 && (
              <AppSelect
                data={cityState.map((city: string) => ({ value: city, label: city }))}
                placeholder={t("components.classesTable.form.cityPlaceholder")}
                {...form_modal.getInputProps("city")}
              />
            )}

            {classState.length > 0 && (
              <AppSelect
                data={classState.map((classes: string) => ({ value: classes, label: classes }))}
                placeholder={t("components.classesTable.form.classNamePlaceholder")}
                {...form_modal.getInputProps("classname")}
              />
            )}
            {matnasState.length > 0 && (
              <AppSelect
                data={matnasState.map((matnas: string) => ({ value: matnas, label: matnas }))}
                placeholder={t("components.classesTable.form.matnasPlaceholder")}
                {...form_modal.getInputProps("matnas")}
              />
            )}
          </div>
          <div className={styles.custom_body}>
            {updatedStudents.length > 0 &&
              updatedStudents.map((student: HistoryStudent, index) => (
                <StudentEditor
                  key={index}
                  student={student}
                  errors={studentErrors[index]}
                  form={form_modal}
                  form_name="students"
                  index={index}
                  onChange={(updatedStudent) => handleStudentChange(index, updatedStudent)}
                  onDelete={() => handleStudentDelete(index)}
                />
              ))}
          </div>
          {Object.keys(studentErrors).length > 0 && <Text c="red">{t("general.requiredAll")}</Text>}
          <div className={styles.modalFooter}>
            <AppButton title={"general.actions.apply"} onClick={handleSave} />
          </div>
        </div>
      </AppModal>

      <AppModal
        status={opened}
        onClose={() => {
          form.reset();
          close();
        }}
        size={"xl"}
      >
        <div className={styles.modal}>
          <div className={styles.modalHead}>
            {form.values.file ? (
              <div className={styles.dropzoneUploaded}>
                <span className={styles.dropzoneFileName}>{form.values.file.name}</span>
                <ActionIcon variant={"transparent"} onClick={() => form.setValues({ file: null })}>
                  <Image src={closeIcon} />
                </ActionIcon>
              </div>
            ) : (
              <>
                <Dropzone
                  openRef={openRef}
                  onDrop={(value) => form.setValues({ file: value[0] })}
                  styles={{ inner: { pointerEvents: "all" } }}
                  style={{ visibility: "hidden", width: "0px", height: "0px" }}
                  accept={[...Object.values(MIME_TYPES)]}
                >
                  {null}
                </Dropzone>
                <AppButton variant="outline" title={t("components.files.actions.chooseFile")} onClick={() => openRef.current()} />
              </>
            )}
          </div>
          <div className={styles.modalBody}>
            <AppInput
              placeholder={t("components.classesTable.form.classNamePlaceholder")}
              {...form.getInputProps("classname")}
              style={{ marginBottom: "10px" }}
            />
            <form onSubmit={form.onSubmit(() => uploadFilesHandler())} className={styles.form}>
              {cities && (
                <AppSelect
                  data={cities.map((city: City) => ({ value: city.name, label: city.name }))}
                  placeholder={t("components.classesTable.form.cityPlaceholder")}
                  {...form.getInputProps("city")}
                />
              )}
              {matnases && (
                <AppSelect
                  data={matnases.map((matnas: Matnas) => ({ value: matnas.name, label: matnas.name }))}
                  placeholder={t("components.classesTable.form.matnasPlaceholder")}
                  {...form.getInputProps("matnas")}
                />
              )}
              <div className={styles.modalActions}>
                <AppButton
                  variant={"outline"}
                  title={"general.actions.cancel"}
                  onClick={() => {
                    close();
                    form.reset();
                  }}
                />
                <AppButton variant={"filled"} type={"submit"} title={"components.files.actions.addFile"} isLoading={isLoading} />
              </div>
            </form>
          </div>
        </div>
      </AppModal>
      <AppButton title={"components.files.actions.addFile"} variant={"filled"} onClick={open} />
    </>
  );
};
