import { Container, Table } from "@mantine/core";
import s from "./HistoryTable.module.scss"
import { HistoryTableRow } from "./HistoryTableRow";
import { PdfUpload } from "./PdfUpload";
import { useGetHistoryQuery } from "../../app/api/history";
import { History } from "../../app/types/historyTypes";
import { useTranslation } from "react-i18next";

export const HistoryTable = () => {
  const {data:histories} = useGetHistoryQuery()
  const { t } = useTranslation();
  return (
    <Container size={"xl"}>
      <div className={s.headerContainer}>
      <PdfUpload />
      </div>
      <Table className={s.table}>
        <thead>
          <tr>
            <th>{t("components.reminds.modal.date")}</th>
            <th>{t("components.files.text.files")}</th>
            <th>{t("studentsPage.filters.city")}</th>
            <th>{t("studentsPage.filters.matnas")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {histories?.map((history:History) => (
            <HistoryTableRow history={history} key={history._id} />
          ))}
        </tbody>
      </Table>
    </Container>
  );
};