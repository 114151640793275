import React from "react";
import styles from "../ClassesTable.module.scss";
import { AppSelect } from "../../Select/Select";
import { AppDatePicker } from "../../Datepicker/Datepicker";
import dayjs from "dayjs";
import { AppButton } from "../../Button/Button";
import { AppModal } from "../../Modal/Modal";
import { useForm } from "@mantine/form";
import { useDeleteClassMutation } from "../../../app/api/classes";
import { useTranslation } from "react-i18next";
import { Checkbox, Stack } from "@mantine/core";
import { AppInput } from "../../Input/Input";
import { ClassType } from "../ClassesTable";

type DeleteClassModalProps = {
  opened: boolean;
  onClose: () => void;
  cls: ClassType;
};

type DeleteType = "single" | "following" | "all";

type FormValues = {
  deleteType: DeleteType;
  date: string | null;
  notifyStudents: boolean;
  reason: string;
};

const defaultFormValues: FormValues = {
  deleteType: "single",
  notifyStudents: false,
  date: null,
  reason: "",
};

const DeleteClassModal = ({ opened, onClose, cls }: DeleteClassModalProps) => {
  const [deleteClass] = useDeleteClassMutation();
  const { values, setFieldValue, reset, onSubmit, getInputProps } = useForm<FormValues>({
    initialValues: defaultFormValues,
    validate: {},
  });
  const { t } = useTranslation();

  const deleteClassHandler = async () => {
    try {
      if (cls.isRecurring) {
        await deleteClass({
          id: cls._id,
          deleteType: values.deleteType,
          recurringEndDate: values.deleteType === "following" ? (values.date as string) : undefined,
          notifyStudents: values.notifyStudents,
          reason: values.notifyStudents ? values.reason : undefined,
        }).unwrap();
      } else {
        await deleteClass({
          id: cls._id,
          notifyStudents: values.notifyStudents,
          reason: values.reason,
        }).unwrap();
      }
    } catch (error) {}
  };

  return (
    <AppModal status={opened} onClose={onClose}>
      <form
        className={styles.modal}
        onSubmit={onSubmit(async () => {
          await deleteClassHandler();
          reset();
          onClose();
        })}
      >
        <Stack>
          {cls.isRecurring && (
            <AppSelect
              data={[
                { label: t("general.modal.single"), value: "single" },
                { label: t("general.modal.all"), value: "all" },
                { label: t("general.modal.following"), value: "following" },
              ]}
              value={values.deleteType}
              placeholder="general.modal.deleteType"
              onChange={(status) => {
                setFieldValue("deleteType", status as DeleteType);
              }}
            />
          )}
          {cls.isRecurring && values.deleteType === "following" && (
            <div className={styles.modalDatepicker}>
              <AppDatePicker changeDate={(value) => setFieldValue("date", dayjs(value).toISOString())} />
            </div>
          )}
          <Checkbox
            label={"Notify students"}
            checked={values.notifyStudents}
            onChange={(event) => setFieldValue("notifyStudents", event.currentTarget.checked)}
          />
          {values.notifyStudents && <AppInput placeholder={"Reason"} {...getInputProps("reason")} withAsterisk />}
          <div className={styles.modalActions}>
            <AppButton
              variant={"outline"}
              title={"general.actions.cancel"}
              onClick={() => {
                onClose();
                reset();
              }}
            />
            <AppButton type={"submit"} variant={"filled"} title={"general.actions.delete"} />
          </div>
        </Stack>
      </form>
    </AppModal>
  );
};

export default DeleteClassModal;
