import React from "react";
import { HistoryStudent } from "../../app/types/historyTypes";
import { AppButton } from "../../components/Button/Button";
import { AppInput } from "../../components/Input/Input";
import styles from "./HistoryTable.module.scss";

interface StudentEditorProps {
  student: HistoryStudent;
  onChange: (updatedStudent: HistoryStudent) => void;
  onDelete: () => void;
  errors?: Record<string, string>;
  form_name?:string;
  index?:number;
  form?:any;
}

export const StudentEditor = React.memo(({ student, onChange, onDelete, errors = {},form_name,index,form }: StudentEditorProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onChange({ ...student, [name]: value });
    form.validateField(`${form_name}.${index}.${name}`);
  };

  return (
    <div className={styles.studentEditor}>
      <AppInput
        type="text"
        name="name"
        value={student.name || ""}
        onChange={handleInputChange}
        placeholder="Name"
        error={(!student.name || student.name.length === 0) && errors?.name}
      />
      <AppInput
        type="text"
        name="student_id"
        value={student.student_id || ""}
        onChange={handleInputChange}
        placeholder="Student ID"
        error={(!student.student_id || student.student_id.length === 0) && errors?.student_id}
      />
      <AppInput
        type="text"
        name="phone"
        value={student.phone || ""}
        onChange={handleInputChange}
        placeholder="Phone"
        error={(!student.phone || student.phone.length === 0) && errors?.phone}
      />
      <AppButton title={"general.actions.remove"} onClick={onDelete} />
    </div>
  );
});
