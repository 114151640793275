import { Flex, Modal, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect } from "react";
import { useGetCityQuery } from "../../app/api/classes";
import { AppInput } from "../../components/Input/Input";
import { AppSelect } from "../../components/Select/Select";
import { AppButton } from "../../components/Button/Button";
import s from "./matnases.module.scss";
import { useTranslation } from "react-i18next";

type FormValues = {
  name: string;
  cityId: string;
};

type CreateUpdateMatnasModalProps = {
  opened: boolean;
  onSubmit: (values: FormValues) => void;
  onClose: () => void;
  submitText: string;
  title: string;
  initFormValues?: FormValues;
};

const defaultFormValues: FormValues = {
  name: "",
  cityId: "",
};

export const CreateUpdateMatnasModal = ({ opened, onSubmit, onClose, submitText, title, initFormValues }: CreateUpdateMatnasModalProps) => {
  const { t } = useTranslation();
  const { data: cities } = useGetCityQuery();
  const form = useForm<FormValues>({
    initialValues: defaultFormValues,
    validate: {
      name: (value) => {
        if (value.trim().length === 0) {
          return "Name is required";
        }
      },
      cityId: (value) => {
        if (value.trim().length === 0) {
          return "City is required";
        }
      },
    },
  });

  useEffect(() => {
    if (!initFormValues) return;
    form.setFieldValue("name", initFormValues.name);
    form.setFieldValue("cityId", initFormValues.cityId);
  }, [initFormValues?.name, initFormValues?.cityId]);

  return (
    <Modal opened={opened} onClose={onClose} withCloseButton={false} centered>
      <form
        className={s.modal}
        onSubmit={form.onSubmit((values) => {
          form.reset();
          onSubmit(values);
        })}
      >
        <div className={s.modalHeader}>{title}</div>
        <Stack>
          <AppInput placeholder={t(`matnasesPage.matnasName`)} {...form.getInputProps("name")} withAsterisk />
          <AppSelect
            placeholder={t(`matnasesPage.city`)}
            value={form.values.cityId}
            onChange={(value) => {
              form.setFieldValue("cityId", value as string);
            }}
            error={form.errors.cityId}
            data={(cities || []).map((city) => ({ value: city._id, label: city.name }))}
          />
          <Flex justify={"flex-end"}>
            <AppButton title={submitText} variant={"filled"} type={"submit"} />
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
};
