import { Button, Container, Table } from "@mantine/core";
import s from "./schools.module.scss";
import { useState } from "react";
import { CreateUpdateSchoolModal } from "./CreateUpdateSchoolModal";
import { SchoolTableRow } from "./SchoolTableRow";
import { useCreateSchoolMutation, useGetSchoolsQuery } from "../../app/api/schools";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../app/contexts/NotificationContext";
import { isServerError } from "../../utils/isError";

export const SchoolsPage = () => {
  const { data: schools } = useGetSchoolsQuery({});
  const [createSchool] = useCreateSchoolMutation();
  const [createSchoolModalOpened, setCreateSchoolModalOpened] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();

  return (
    <>
      <CreateUpdateSchoolModal
        title={t("schoolsPage.addSchool")}
        opened={createSchoolModalOpened}
        onClose={() => {
          setCreateSchoolModalOpened(false);
        }}
        submitText={t("general.actions.add")}
        onSubmit={async ({ name, cityId }) => {
          setCreateSchoolModalOpened(false);
          try {
            await createSchool({ name, cityId }).unwrap();
          } catch (e) {
            if (isServerError(e)) {
              addNotification({ text: e.data.message, type: "error" });
            }
          }
        }}
      />
      <Container size={"xl"}>
        <Button
          onClick={() => {
            setCreateSchoolModalOpened(true);
          }}
        >
          {t("schoolsPage.addSchool")}
        </Button>
        <Table className={s.table}>
          <thead>
            <tr>
              <th>{t("schoolsPage.schoolName")}</th>
              <th>{t("schoolsPage.city")}</th>
              <th>{t("schoolsPage.actionsColumnTitle")}</th>
            </tr>
          </thead>
          <tbody>
            {schools?.map((school) => (
              <SchoolTableRow school={school} key={school._id} />
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};
