import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";
import { School } from "../types/schoolsTypes";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,

  prepareHeaders: (headers, { getState }) => {
    headers.set("content-type", "application/json");
    const token = (getState() as RootState).auth.token || localStorage.getItem("auth_token");

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const schoolsApi = createApi({
  reducerPath: "schoolsApi",
  baseQuery: baseQuery,
  tagTypes: ["Schools"],
  endpoints: (builder) => ({
    getSchools: builder.query<School[], { name?: string; cityId?: string }>({
      query: ({ name, cityId }) => ({
        url: `/schools`,
        method: "GET",
        params: { name, cityId },
      }),
      providesTags: ["Schools"],
    }),
    deleteSchool: builder.mutation<void, string>({
      query: (schoolId) => ({
        url: `/schools/${schoolId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Schools"],
    }),
    createSchool: builder.mutation<void, { name: string; cityId: string }>({
      query: ({ name, cityId }) => ({
        url: `/schools`,
        method: "POST",
        body: { name, cityId },
      }),
      invalidatesTags: ["Schools"],
    }),
    updateSchool: builder.mutation<void, { _id: string; name: string; cityId: string }>({
      query: ({ _id, name, cityId }) => ({
        url: `/schools/${_id}`,
        method: "PATCH",
        body: { name, cityId },
      }),
      invalidatesTags: ["Schools"],
    }),
  }),
});

export const { useGetSchoolsQuery, useDeleteSchoolMutation, useCreateSchoolMutation, useUpdateSchoolMutation } = schoolsApi;
