import React, { useState } from "react";
import { CreateUpdateSchoolModal } from "./CreateUpdateSchoolModal";
import s from "./schools.module.scss";
import { Button } from "@mantine/core";
import { School } from "../../app/types/schoolsTypes";
import { useDeleteSchoolMutation, useUpdateSchoolMutation } from "../../app/api/schools";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../app/contexts/NotificationContext";
import { isServerError } from "../../utils/isError";
import { NotificationWithOptions } from "../../components/NotificationWithOptions/notificationWithOptions";

type SchoolsTableRowProps = {
  school: School;
};

export const SchoolTableRow = ({ school }: SchoolsTableRowProps) => {
  const [deleteSchool] = useDeleteSchoolMutation();
  const [updateSchool] = useUpdateSchoolMutation();
  const [updateSchoolModalOpened, setUpdateSchoolModalOpened] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const [showDeleteNotification, setShowDeleteNotification] = useState(false);

  return (
    <>
      {showDeleteNotification && (
        <NotificationWithOptions
          action={() => {
            setShowDeleteNotification(false);
            deleteSchool(school._id);
          }}
          text={`${t("schoolsPage.deleteNotificationText")} ${school.name}`}
          onClose={() => setShowDeleteNotification(false)}
        />
      )}
      <CreateUpdateSchoolModal
        initFormValues={{ name: school.name, cityId: school.city._id }}
        title={t(`schoolsPage.updateSchool`)}
        opened={updateSchoolModalOpened}
        onClose={() => {
          setUpdateSchoolModalOpened(false);
        }}
        submitText={t(`general.actions.update`)}
        onSubmit={async ({ name, cityId }) => {
          setUpdateSchoolModalOpened(false);
          try {
            await updateSchool({ _id: school._id, name, cityId }).unwrap();
          } catch (e) {
            if (isServerError(e)) {
              addNotification({ text: e.data.message, type: "error" });
            }
          }
        }}
      />
      <tr>
        <td className={s.nameTableCell}>{school.name}</td>
        <td className={s.nameTableCell}>{school.city.name}</td>
        <td>
          <div className={s.tableActions}>
            <Button
              onClick={() => {
                setUpdateSchoolModalOpened(true);
              }}
            >
              {t("general.actions.update")}
            </Button>
            <Button
              onClick={() => {
                setShowDeleteNotification(true);
              }}
            >
              {t("general.actions.delete")}
            </Button>
          </div>
        </td>
      </tr>
    </>
  );
};
