import { Container, Image } from "@mantine/core";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import successIcon from "../../assets/icons/success.svg";
import alertIcon from "../../assets/icons/alert.svg";
import styles from "./notification.module.scss";

export interface NotificationProps {
  duration?: number;
  text: string;
  onClose?: () => void;
  type?: "success" | "error";
}

export const Notification: FC<NotificationProps> = ({ text, onClose, duration = 3000, type = "success" }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onClose?.();
    }, duration);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const iconSrc = type === "success" ? successIcon : alertIcon;

  return (
    <div className={styles.popover}>
      <Container size={"xl"}>
        <div className={styles.popoverInner}>
          <span className={styles.popoverText}>{t(text)}</span>
          <Image className={styles.popoverImage} src={iconSrc} />
        </div>
      </Container>
    </div>
  );
};
