import s from "./students-attendance.module.scss";
import { StudentAttendance } from "../../app/types/studentTypes";
import { ExportStudentAttendanceButton } from "../../components/ExportStudentAttendanceButton/ExportStudentAttendanceButton";

type StudentAttendanceTableRowProps = {
  studentAttendance: StudentAttendance;
};

export const StudentAttendanceTableRow = ({ studentAttendance }: StudentAttendanceTableRowProps) => {
  const fileName = `${studentAttendance.fullName}-attendance.csv`;
  return (
    <>
      <tr>
        <td className={s.nameTableCell}>{studentAttendance.fullName}</td>
        <td className={s.nameTableCell}>{studentAttendance.present}</td>
        <td className={s.nameTableCell}>{studentAttendance.absent}</td>
        <td>
          <ExportStudentAttendanceButton studentId={studentAttendance._id} fileName={fileName} />
        </td>
      </tr>
    </>
  );
};
