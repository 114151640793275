import React, { useEffect } from "react";
import dayjs from "dayjs";
import { AppButton } from "../../../components/Button/Button";
import { CSVLink } from "react-csv";
import { useLazyGetClassesQuery } from "../../../app/api/classes";
import { useTranslation } from "react-i18next";

type ExportClassesButtonProps = {
  startDate?: Date;
  endDate?: Date;
};

export const ExportClassesToCSVFileButton = ({ startDate, endDate }: ExportClassesButtonProps) => {
  const [trigger, { data: classes, isLoading, status }] = useLazyGetClassesQuery();
  const csvLinkId = "export-classes-csv-link";
  const { t } = useTranslation();

  useEffect(() => {
    if (status === "fulfilled") {
      document.getElementById(csvLinkId)?.click();
    }
  }, [status]);

  return (
    <>
      <AppButton
        variant={"filled"}
        title={t("classesPage.actions.exportClasses")}
        onClick={() => {
          trigger({
            ...(startDate && { startDate: dayjs(startDate).toISOString() }),
            ...(endDate && { endDate: dayjs(endDate).toISOString() }),
          });
        }}
        loading={isLoading}
      />
      {classes && <CSVLink id={csvLinkId} data={classes} filename={"classes.csv"} />}
    </>
  );
};
