import { useCreateCityMutation, useGetCityQuery } from "../../app/api/classes";
import { Button, Container, Table } from "@mantine/core";
import s from "./cities.module.scss";
import React, { useState } from "react";
import { CreateUpdateCityModal } from "./CreateUpdateCityModal";
import { CitiesTableRow } from "./CitiesTableRow";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../app/contexts/NotificationContext";
import { isServerError } from "../../utils/isError";

export const CitiesPage = () => {
  const { data: cities } = useGetCityQuery();
  const [createCity] = useCreateCityMutation();
  const [createCityModalOpened, setCreateCityModalOpened] = useState(false);
  const { t } = useTranslation();
  const { addNotification } = useNotification();

  return (
    <>
      <CreateUpdateCityModal
        title={t("citiesPage.addCity")}
        opened={createCityModalOpened}
        onClose={() => {
          setCreateCityModalOpened(false);
        }}
        submitText={t("general.actions.add")}
        onSubmit={async ({ name }) => {
          setCreateCityModalOpened(false);
          try {
            await createCity(name).unwrap();
          } catch (e) {
            if (isServerError(e)) {
              addNotification({ text: e.data.message, type: "error" });
            }
          }
        }}
      />
      <Container size={"xl"}>
        <Button
          onClick={() => {
            setCreateCityModalOpened(true);
          }}
        >
          {t("citiesPage.addCity")}
        </Button>
        <Table className={s.table}>
          <thead>
            <tr>
              <th>{t("citiesPage.cityName")}</th>
              <th>{t("citiesPage.actionsColumnTitle")}</th>
            </tr>
          </thead>
          <tbody>
            {cities?.map((city) => (
              <CitiesTableRow city={city} key={city._id} />
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};
