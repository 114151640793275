type ServerError = {
  data: { message: string };
};

export const isServerError = (error: any): error is ServerError => {
  if (typeof error === "object" && error !== null) {
    if ("data" in error && typeof error.data === "object" && error.data !== null) {
      if ("message" in error.data && typeof error.data.message === "string") {
        return true;
      }
    }
  }
  return false;
};
