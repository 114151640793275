import { NotificationProvider } from "./app/contexts/NotificationContext";
import { AuthProvider } from "./app/hooks/useAuth";
import { Layout } from "./components/Layout/Layout";
import i18n from "i18next";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.body.dir = i18n.dir();
    const browserLng = window.navigator.language;
    const language = browserLng === "he" ? browserLng : "en"
    // const language = "en";
    i18n.changeLanguage(language);
  }, []);

  return (
    <AuthProvider>
      <NotificationProvider>
        <Layout />
      </NotificationProvider>
    </AuthProvider>
  );
}

export default App;
