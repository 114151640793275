import { History, HistoryStudent, UpdateHistoryStudents } from "../../app/types/historyTypes";
import { StudentEditor } from "./StudentEditor";
import { AppModal } from "../../components/Modal/Modal";
import { AppButton } from "../../components/Button/Button";
import styles from "./HistoryTable.module.scss";
import { useTranslation } from "react-i18next";
import { useUpdateHistoryStudentsMutation } from "../../app/api/history";
import { useDisclosure } from "@mantine/hooks";
import { AppSelect } from "../Select/Select";
import { FormErrors, useForm } from "@mantine/form";
import { Text } from "@mantine/core";
import { useEffect, useMemo } from "react";

interface StudentsModalProps {
  history: History;
}

const removeDuplicates = (arr: string[]): string[] => arr.filter((item, index) => arr.indexOf(item) === index);

const groupStudentErrors = (errors: FormErrors) => {
  const groupedErrors: Record<number, Record<string, string>> = {};

  Object.keys(errors).forEach((key) => {
    const match = key.match(/updatedStudents\.(\d+)\.(\w+)/);
    if (match) {
      const [, index, field] = match;
      const studentIndex = parseInt(index, 10);

      if (!groupedErrors[studentIndex]) {
        groupedErrors[studentIndex] = {};
      }

      groupedErrors[studentIndex][field] = errors[key] as string;
    }
  });

  return groupedErrors;
};

export const StudentsModal = ({ history }: StudentsModalProps) => {
  const { t } = useTranslation();
  const [updateHistoryStudents, { isLoading }] = useUpdateHistoryStudentsMutation();
  const { students, city, matnas, classname, _id } = history;
  const [openedStudents, { open, close: closeStudents }] = useDisclosure();
  const form = useForm({
    initialValues: {
      cityState: '',
      matnasState: '',
      classState: '',
      updatedStudents: students ?? []
    },
    validate: {
      cityState: (value) => (value ? null : t("general.required")),
      matnasState: (value) => (value ? null : t("general.required")),
      updatedStudents: {
        name: (value) => (value ? null : t("general.required")),
        phone: (value) => (value ? null : t("general.required")),
      },
    },
  });

  const dataCity = removeDuplicates(city);
  const dataMatnas = removeDuplicates(matnas);
  const dataClassname = removeDuplicates(classname);

  const handleSave = async () => {
    if (!form.validate().hasErrors) {
      const body: UpdateHistoryStudents = {
        historyId: _id,
        city: form.values.cityState,
        matnas: form.values.matnasState,
        classname: form.values.classState,
        students: form.values.updatedStudents,
      };

      try {
        await updateHistoryStudents({ body });
        closeStudents();
      } catch (error) {}
    }
  };
  const studentErrors = useMemo(() => groupStudentErrors(form.errors), [form.errors]);

  return (
    <>
      <AppModal status={openedStudents} onClose={closeStudents} size={"xl"}>
        <div className={styles.modal}>
          <div className={styles.modalHead}>
            {dataCity.length > 0 && (
              <AppSelect
                data={dataCity.map((city: string) => ({ value: city, label: city }))}
                placeholder={t("components.classesTable.form.cityPlaceholder")}
                {...form.getInputProps('cityState')}
              />
            )}
            {dataClassname.length > 0 && (
              <AppSelect
                data={dataClassname.map((classes: string) => ({ value: classes, label: classes }))}
                placeholder={t("components.classesTable.form.classNamePlaceholder")}
                {...form.getInputProps('classState')}
              />
            )}
            {dataMatnas.length > 0 && (
              <AppSelect
                data={dataMatnas.map((matnas: string) => ({ value: matnas, label: matnas }))}
                placeholder={t("components.classesTable.form.matnasPlaceholder")}
                {...form.getInputProps('matnasState')}
              />
            )}
          </div>
          <div className={styles.custom_body}>
            {form.values.updatedStudents.length > 0 &&
              form.values.updatedStudents.map((student: HistoryStudent, index) => (
                <StudentEditor
                  key={index}
                  student={student}
                  onChange={(updatedStudent) => {
                    const newStudents = [...form.values.updatedStudents];
                    newStudents[index] = updatedStudent;
                    form.setFieldValue('updatedStudents', newStudents);
                  }}
                  form={form}
                  form_name="updatedStudents"
                  index={index}
                  errors={studentErrors[index]}
                  onDelete={() => {
                    const newStudents = form.values.updatedStudents.filter((_, i) => i !== index);
                    form.setFieldValue('updatedStudents', newStudents);
                  }}
                />
              ))}
          </div>
          {Object.keys(studentErrors).length > 0 && <Text c="red">{t("general.requiredAll")}</Text>}
          <div className={styles.modalFooter}>
            <AppButton title={"general.actions.apply"} onClick={handleSave} />
          </div>
        </div>
      </AppModal>
      <AppButton title={"general.actions.approve"} onClick={open} isLoading={isLoading} />
    </>
  );
};
